import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const userRole = await this.userService.getUserRole();
    const routePath = state.url;

    if (
      (routePath.startsWith('/customer') && userRole === 'customer') ||
      (routePath.startsWith('/employee') && userRole === 'employee') ||
      (routePath.startsWith('/profile') && userRole.length > 0)
    ) {
      return true;
    }

    return this.router.createUrlTree(['/login']);
  }
}
