import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, lastValueFrom, Observable, switchMap} from "rxjs";
import {environment} from "../../environments/environment";
import { UtilService } from '../services/util.service';


@Injectable()
export class DevModeInterceptor implements HttpInterceptor {

    constructor(
        private utilService: UtilService
    ){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return from(this.handleRequest(request, next));
    }

    private async handleRequest(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        const isDevMode = await this.utilService.get<boolean>('devMode');

        let modifiedReq = req;
        if (isDevMode) {
          const devBaseUrl = environment.devApi;
          const prodBaseUrl = environment.api;
    
          if (req.url.startsWith(prodBaseUrl)) {
            const newUrl = req.url.replace(prodBaseUrl, devBaseUrl);
            modifiedReq = req.clone({ url: newUrl });
          }
        }
        
        return lastValueFrom(next.handle(modifiedReq))
      }
}