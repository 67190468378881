import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import {
  FirebaseMessaging,
  NotificationActionPerformedEvent,
  NotificationReceivedEvent
} from '@capacitor-firebase/messaging';
import { Notification } from '../models/notification';
import { UtilService } from './util.service';
import { ToastButton, ToastController } from '@ionic/angular';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private http: HttpClient,
    private utilsService: UtilService,
    private toastController: ToastController,
    private notificationService: NotificationService
  ) { }

  async init() {
    if(!Capacitor.isNativePlatform()) {
      return;
    }
    const permStatus = await FirebaseMessaging.requestPermissions();
    if (permStatus.receive !== 'granted') {
      return; // No permission for push messages given
    }

    const token = await FirebaseMessaging.getToken();
    await this.registerToken(token.token);

    FirebaseMessaging.addListener('notificationReceived', async (notification: NotificationReceivedEvent) => {
      this.handleForegroundNotification(notification);
    });

    FirebaseMessaging.addListener('notificationActionPerformed', (notification: NotificationActionPerformedEvent) => {
      this.handleBackgroundNotification(notification);
    });
  }

  async handleForegroundNotification(notificationEvent: NotificationReceivedEvent){
    const notification: Notification = JSON.parse((notificationEvent.notification.data as { notification: string }).notification);

    let toastButtons: ToastButton[] = [];
    toastButtons = [
      {
        text: await this.utilsService.translate('LBL_OPEN'),
        handler: async () => {
          await this.utilsService.redirectNotification(notification);
        }
      }
    ];

    await this.notificationService.getNotifications();

    await this.showNotificationToast(
      notificationEvent.notification.title!,
      notificationEvent.notification.body!,
      5000,
      toastButtons
    );
  }

  async showNotificationToast(header: string, message: string, duration: number, buttons: ToastButton[]){
    const t = await this.toastController.create({
      header,
      message,
      duration,
      layout: 'stacked',
      buttons
    });
    await t.present();
  }

  async handleBackgroundNotification(notificationEvent: NotificationActionPerformedEvent){
    const notification: Notification = JSON.parse((notificationEvent.notification.data as { notification: string }).notification);
    await this.utilsService.redirectNotification(notification);
  }

  async registerToken(token: string): Promise<boolean>{
    try{
      let tokenResponse = await lastValueFrom(
        this.http.post<any>(`${environment.api}/register`,
          {
            device_token: token
          }
        )
      )
      console.log(tokenResponse);
      return tokenResponse === 'success';
    } catch (e) {
      console.log(e);
    }
    return false;
  }
}
