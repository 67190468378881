import { Notification } from './../models/notification';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  counter: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) { }

  async getNotifications(): Promise<Notification[]>{
    try{
      let notificationResponse = await lastValueFrom(
        this.http.get<Notification[]>(`${environment.api}/notifications`)
      )
      this.counter.next(notificationResponse.filter(i => !i.read).length)
      return notificationResponse;
    } catch (e){
      console.log(e);
    }
    return [];
  }

  async readNotification(id: number): Promise<boolean>{
    try{
      let notificationResponse = await lastValueFrom(
        this.http.put<Notification[]>(`${environment.api}/notifications/${id}/read`, null)
      )
      this.counter.next(this.counter.value-1);
      if(notificationResponse){
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async readAllNotifications(): Promise<Notification[]>{
    try{
      let notificationResponse = await lastValueFrom(
        this.http.put<Notification[]>(`${environment.api}/notifications/read-all`, null)
      )
      this.counter.next(0);
      return notificationResponse
    } catch (e) {
      console.log(e);
    }
    return [];
  }
}