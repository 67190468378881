import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  async initializeApp(user?: User | null): Promise<void> {
    if(!user){
      user = await this.userService.getUser();
    }

    if (user) {
      const role = await this.userService.getUserRole(user);
      if (role === 'employee') {
        await this.router.navigateByUrl('/employee');
      } else if (role === 'customer') {
        await this.router.navigateByUrl('/customer');
      } else if (role === 'customer-admin'){
        await this.router.navigateByUrl('/customer');
      }
    } else {
      await this.router.navigateByUrl('/login');
    }
  }
}
