import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { lastValueFrom, skip } from 'rxjs';
import { UtilService } from './util.service';
import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
  ) { }

  async resetPassword(email: string): Promise<boolean>{
    try{
      let resetResponse = await lastValueFrom(
        this.http.post<any>(`${environment.api}/password-reset/request`,
          {
            email
          }
        )
      )
      return resetResponse;
    } catch (e) {
      console.log(e);
    }
    return false
  }

  async login(email: string, password: string): Promise<User | null>{
    try {
      let loginResponse = await lastValueFrom(
        this.http.post<any>(`${environment.api}/login`,
          {
            email,
            password
          }
        )
      )
      if(loginResponse.access_token && loginResponse.access_token.length > 0){
        await this.utilService.set<string>('token', loginResponse.access_token);

        const user = await this.getUser();

        return user;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async logout(skipConfirm: boolean = false): Promise<boolean>{
    try {
      if (skipConfirm || await this.utilService.confirm('LBL_LOGOUT')) {
        await this.utilService.delete('user');
        await this.utilService.delete('token');
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async updateUser(user: User): Promise<boolean>{
    try{
      let userResponse = await lastValueFrom(
        this.http.put<User>(`${environment.api}/user/update`, user)
      )
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async updatePassword(password: string): Promise<boolean>{
    try{
      let userResponse = await lastValueFrom(
        this.http.put<User>(`${environment.api}/user/update`, {
          password: password
        })
      )
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async deleteUser(): Promise<boolean>{
    try{
      let deleteResponse = await lastValueFrom(
        this.http.put<string>(`${environment.api}/user/delete`, null)
      )
      return deleteResponse === 'success';
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async getToken(): Promise<string | null>{
    return this.utilService.get<string>('token');
  }

  async getUser(): Promise<User | null>{
    try{
      const userResponse = await lastValueFrom(
        this.http.get<User>(`${environment.api}/user`)
      )
      if(userResponse){
        return userResponse;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getUserRole(user?: User): Promise<string>{
    if(!user){
      user = await this.getUser() || undefined;
    }
    if(user){
      if(user.roles.length > 0){
        return user.roles[0].name;
      }
    }
    return '';
  }
}
