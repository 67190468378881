import { Component } from '@angular/core';
import { User } from './models/user';
import { UserService } from './services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FcmService } from './services/fcm.service';
import { NotificationService } from './services/notification.service';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  user: User | null = null;

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private fcmService: FcmService,
    private notificationService: NotificationService,
    private platform: Platform
  ) {
    this.init();
  }

  async init(){
    this.translateService.addLangs(['de']);
    this.translateService.setDefaultLang('de');
    this.translateService.use('de');

    this.user = await this.userService.getUser();
    if(this.user){
      await this.fcmService.init();
      await this.notificationService.getNotifications();
    }

    this.platform.ready().then(() => {
      this.reload();
    });
  }

  reload() {
    if (!sessionStorage.getItem('appReloaded')) {
      sessionStorage.setItem('appReloaded', 'true');
      window.location.reload();
    }
  }
}
