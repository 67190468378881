import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Config } from 'src/app/models/config';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent  implements OnInit {

  @Input() configs: Config[] = [];
  @Input() title: string = '';
  @Input() multi: boolean = false;
  @Input() selectedConfigs: Config[] = []; // used when multi is true

  filteredConfigs: Config[] = [];

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.filteredConfigs = [...this.configs];
  }

  search(event: any){
    const input = event.detail.value;
    if(input.length > 0){
      const searchQuery = input.toLowerCase();
      this.filteredConfigs = this.configs.filter((config: Config) => {
        return config.title.toLowerCase().includes(searchQuery);
      });
    } else {
      this.filteredConfigs = [...this.configs];
    }
  }

  async cancel(){
    await this.modalController.dismiss();
  }

  select(config: Config){
    if(this.multi){
      const ix = this.selectedConfigs.findIndex(c => c.id === config.id);
      if(ix >= 0){
        this.selectedConfigs.splice(ix, 1);
      } else {
        this.selectedConfigs.push(config);
      }
    } else {
      this.modalController.dismiss(config)
    }
  }

  isChecked(config: Config): boolean{
    return this.selectedConfigs.some(selected => selected.id === config.id);
  }

  saveMulti(){
    this.modalController.dismiss(this.selectedConfigs);
  }
}
