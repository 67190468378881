<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()" fill="clear" color="medium">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title | translate }}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      (ionInput)="search($event)"
      placeholder="{{ 'LBL_SEARCH' | translate}}"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-list id="modal-list" class="ion-no-padding" *ngIf="configs">
    <ng-container *ngIf="multi">
      <ion-item *ngFor="let c of filteredConfigs">
        <ion-checkbox (ionChange)="select(c)" [value]="c.id" [checked]="isChecked(c)">
          {{ c.title }}
        </ion-checkbox>
      </ion-item>
      
    </ng-container>
    <ng-container *ngIf="!multi">
      <ion-item *ngFor="let c of filteredConfigs" (click)="select(c)" detail>
        <div>{{ c.title }}</div>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-button *ngIf="multi" expand="block" class="ion-padding" (click)="saveMulti()">
    {{ 'LBL_SUBMIT_MULTI_TYPEAHEAD' | translate}}
  </ion-button>
</ion-footer>
