import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, switchMap} from "rxjs";
import { UserService } from '../services/user.service';
import {environment} from "../../environments/environment";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private userService: UserService
    ){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.userService.getToken()).pipe(switchMap(token => {
            if (
                (request.url.startsWith(environment.api) || request.url.startsWith(environment.devApi)) &&
                !request.url.includes('/login') &&
                token
            ) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
            return next.handle(request);
        }));
    }
}