import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { OperationArea } from '../models/operationArea';
import { Notification } from '../models/notification';
import { Router } from '@angular/router';
import { TypeaheadComponent } from '../components/typeahead/typeahead.component';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    private translateService: TranslateService,
    private toastController: ToastController,
    private alertController: AlertController,
    private router: Router,
    private modalController: ModalController
  ) {
    this.init();
  }

  async init() {
    this._storage = await this.storage.create();
  }

  async set<T>(key: string, value: T): Promise<boolean> {
    try {
      await this._storage?.set(key, value);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async get<T>(key: string): Promise<T|null> {
    try {
      return (await this.storage.get(key)) as T;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async delete(key: string): Promise<boolean> {
    try {
      await this.storage.remove(key);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async openTypeaheadModal(
    configs: Config[],
    title: string,
    multi: boolean = false,
    selectedConfigs?: Config[]
  ): Promise<Config | Config[]> {
    const modal = await this.modalController.create({
      component: TypeaheadComponent,
      backdropDismiss: false,
      componentProps: {
        configs,
        title,
        multi,
        selectedConfigs
      }
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async redirectNotification(notification: Notification){
    if(notification.message_type === 'interesting_request_found_push' && notification.work_id){
      await this.router.navigateByUrl('/employee/work/' + notification.work_id);
    } else if(notification.message_type === 'job_matched_customer_push' && notification.matching_id) {
      await this.router.navigateByUrl('/customer/matchings/' + notification.matching_id);
    } else if(
      (notification.message_type === 'reminder_push' || notification.message_type === 'need_times_reminder_push') &&
      notification.matching_id
    ) {
      await this.router.navigateByUrl('/employee/matchings/' + notification.matching_id);
    }
  }

  formatDateString(value: string): string{
    return value.substring(0, 19).replace('T', ' ');
  }

  async confirm(
    header = 'LBL_CONFIRM',
    message = 'LBL_CONFIRM_MESSAGE',
    cancelLabel = 'LBL_NO',
    confirmLabel = 'LBL_YES',
  ): Promise<boolean> {
      return new Promise(async (resolve) => {
        const alert = await this.alertController.create({
        header: await this.translate(header),
        message: await this.translate(message),
        buttons: [
          {
            text: await this.translate(cancelLabel),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          },
          {
            text: await this.translate(confirmLabel),
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      await alert.present();
    });
  }

  async showToast(message: string, duration = 3000){
    const t = await this.toastController.create({
      message: await this.translate(message),
      duration
    });
    await t.present();
  }

  async translate(text: string, params: any = {}): Promise<string> {
    return await lastValueFrom(this.translateService.get(text, params));
  }

  getCountryStates(): OperationArea[]{
    return [
      {
        id: 1,
        label: 'LBL_BADEN_WUERTTEMBERG'
      },
      {
        id: 2,
        label: 'LBL_BAVARIA'
      },
      {
        id: 3,
        label: 'LBL_BERLIN'
      },
      {
        id: 4,
        label: 'LBL_BRANDENBURG'
      },
      {
        id: 5,
        label: 'LBL_BREMEN'
      },
      {
        id: 6,
        label: 'LBL_HAMBURG'
      },
      {
        id: 7,
        label: 'LBL_HESSE'
      },
      {
        id: 8,
        label: 'LBL_MECKLENBURG'
      },
      {
        id: 9,
        label: 'LBL_LOWER_SAXONY'
      },
      {
        id: 10,
        label: 'LBL_NORTHRHINE_WESTPHALIA'
      },
      {
        id: 11,
        label: 'LBL_RHINELAND_PALATINATE',
      },
      {
        id: 12,
        label: 'LBL_SAARLAND',
      },
      {
        id: 13,
        label: 'LBL_SAXONY_ANHALT',
      },
      {
        id: 14,
        label: 'LBL_SAXONY',
      },
      {
        id: 15,
        label: 'LBL_SCHLESWIG',
      },
      {
        id: 16,
        label: 'LBL_THURINGIA'
      },
    ]
  }

  getStateLabel(id: number): string {
    return this.getCountryStates().find(i => i.id === id)?.label || '';
  }

  isSameDay(dateOne: Date | string, dateTwo: Date | string){
    dateOne = new Date(dateOne);
    dateTwo = new Date(dateTwo);
    return (
      dateOne.getDate() === dateTwo.getDate() &&
      dateOne.getMonth() === dateTwo.getMonth() &&
      dateOne.getFullYear() === dateTwo.getFullYear()
    )
  }

  timezoneFix(givenDate: Date){
    return new Date(givenDate.getTime() - givenDate.getTimezoneOffset() * 60000).toISOString()
  }
}
